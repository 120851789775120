import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Container,
  EmailForm,
  FlexWrapper,
  H3,
  Logo,
  Small,
  Svg,
} from 'components';
import { useRouter } from 'apis/history';
import { useDispatch, useSelector } from 'react-redux';
import { createLead, updateEmail } from 'state/user/effects';
import { AppState } from 'state/types';
import Line1Icon from 'assets/icons/background_line_1.svg';
import Line2Icon from 'assets/icons/background_line_2.svg';
import { mobile, useQuery } from 'styles/breakpoints';
import { almostWhite, blue } from 'styles/colors';

const Email: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { goToCheckout } = useRouter();
  const { isMobile } = useQuery();
  const { quiz_answers } = useSelector((s: AppState) => s.user);

  const handleEmailFormSubmit = async (email: string) => {
    try {
      setIsDisabled(true);
      await dispatch(updateEmail(email));
      await dispatch(createLead({ email, funnel: 'main', quiz_answers }));
      goToCheckout();
    } catch (e) {
      setIsDisabled(false);
      setErrorMsg(e?.response?.data?.message);
    }
  };

  return (
    <Background>
      {!isMobile && (
        <>
          <Line1 />
          <Line2 />
        </>
      )}
      <Container>
        <Logo />
        <Layout
          margin="0 auto"
          maxWidth="38rem"
          alignItems="center"
          justifyContent={isMobile ? 'flex-start' : 'center'}
          flexDirection="column"
        >
          <FlexWrapper
            justifyContent="center"
            margin={isMobile ? '1.5rem 0 0.5rem' : '8.125rem 0 0.625rem'}
            flexDirection="column"
            alignItems="center"
          >
            <Svg
              src="icons/leaves_2"
              width="52"
              height="36"
              viewBox="0 0 52 36"
              fill="none"
              style={{
                display: 'block',
              }}
            />
            <H3 color={blue} margin="0.5rem 0 2rem 0">
              Claim your FREE shipping and get Pulsio at a limited-time,
              discount price!
            </H3>
            <EmailForm
              onSubmit={handleEmailFormSubmit}
              errorMessage={errorMsg}
              isDisabled={isDisabled}
            />
          </FlexWrapper>
        </Layout>
      </Container>
      <BottomText color={blue}>
        By continuing, you agree with future information from us.
      </BottomText>
    </Background>
  );
});

Email.displayName = 'Email';

export default Email;

const Background = styled.div`
  position: relative;
  min-height: 100vh;
  padding-top: 1.25rem;
  background: ${almostWhite};
  overflow: hidden;
`;
const Layout = styled(FlexWrapper)`
  min-height: 70vh;
  text-align: center;
  @media ${mobile} {
    min-height: 80vh;
  }
`;
const Line1 = styled(Line1Icon)`
  position: absolute;
  top: 0;
  left: 0;
`;
const Line2 = styled(Line2Icon)`
  position: absolute;
  bottom: 0;
  right: 0;
`;
const BottomText = styled(Small)`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  opacity: 0.5;
  @media ${mobile} {
    bottom: 1rem;
    left: 1rem;
    max-width: 20.438rem;
  }
`;
